<template>
  <div>
    <div
      class="fixed h-full translate-x-[-320px] w-[300px] top-0 left-0 bg-white z-20 transition-all transform duration-500"
      :class="{
        '!translate-x-0': open_menu,
      }"
    >
      <div class="overflow-y-auto max-h-full w-full flex flex-col gap-2 p-6">
        <nuxt-link to="/features" class="px-3 py-2 cursor-pointer">
          {{ $t("features") }}
        </nuxt-link>
        <nuxt-link to="/batch-qr-code" class="px-3 py-2 cursor-pointer">
         {{ $t('batch') }}
        </nuxt-link>
        <nuxt-link to="/qr-code-scanner" class="px-3 py-2 cursor-pointer">
          {{ $t("scanner") }}
        </nuxt-link>
        <div class="px-3 py-2">
          <p class="font-bold mb-3 text-base">{{ $t("solutions") }}</p>
          <div class="flex flex-col ml-2">
            <nuxt-link
              to="/qr-codes-in-marketing"
              class="px-3 py-2 cursor-pointer"
            >
              {{ dataLocale.data.marketing }}
            </nuxt-link>
            <nuxt-link
              to="/qr-codes-in-education"
              class="px-3 py-2 cursor-pointer"
            >
              {{ dataLocale.data.education }}
            </nuxt-link>
            <nuxt-link
              to="/qr-codes-for-personal"
              class="px-3 py-2 cursor-pointer"
            >
              {{ dataLocale.data.personal }}
            </nuxt-link>
            <nuxt-link
              to="/qr-codes-for-nonprofit"
              class="px-3 py-2 cursor-pointer"
            >
              {{ dataLocale.data.nonprofit }}
            </nuxt-link>
            <nuxt-link to="/qr-codes-for" class="px-3 py-2 cursor-pointer">
              {{ dataLocale.data.business }}
            </nuxt-link>
          </div>
        </div>
        <nuxt-link to="/pricing" class="px-3 py-2 cursor-pointer">
          Pricing
        </nuxt-link>
        <div class="px-3 py-2">
          <p class="font-bold mb-3 text-base">Resources</p>
          <div class="flex flex-col ml-2">
            <nuxt-link to="/contact-us" class="px-3 py-2 cursor-pointer">
              {{ $t("contact") }}
            </nuxt-link>
            <nuxt-link to="/blog" class="px-3 py-2 cursor-pointer">
              Blog
            </nuxt-link>
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <LayoutsNavigationLanguageSelectLocale />
          <a
            :href="useRuntimeConfig().public.app_user_url + '/sign-in'"
            target="_blank"
            rel="noopener noreferrer"
            class="text-center flex items-center justify-center rounded-lg h-11 px-5 bg-white text-neutrals-15 shadow-button font-bold whitespace-nowrap"
          >
            {{ $t("login") }}
          </a>
          <a
            :href="useRuntimeConfig().public.app_user_url + '/sign-up'"
            target="_blank"
            rel="noopener noreferrer"
            class="text-center flex items-center justify-center rounded-lg h-11 px-5 bg-success text-white font-bold whitespace-nowrap"
          >
            {{ $t("sign_up") }}
          </a>
        </div>
      </div>
    </div>
    <div
      class="fixed bg-black z-[1] top-0 left-0 w-full h-full transition-all ease-in duration-150"
      :class="{
        'opacity-0 invisible': !open_menu,
        'opacity-20 visible': open_menu,
      }"
      @click="toggleOpenMenu()"
    ></div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  open_menu: {
    type: Boolean,
    default: false,
  },
  dataLocale: {
    type: Object as PropType<{
      data: any;
    }>,
    required: true,
  },
});
const emit: Function = defineEmits();
const toggleOpenMenu = () => {
  emit("close");
};
</script>
