<template>
  <nuxt-link
    :to="localePath(to)"
    class="text-neutrals-15 whitespace-nowrap hover:bg-neutrals-10 text-md font-normal hover:bg-opacity-10 py-2.5 px-3 xl:px-4 rounded-full transition flex items-center dark:text-white"
  >
    <slot />
  </nuxt-link>
</template>
<script setup>
const props = defineProps({
  to: {
    type: String,
    required: true,
  },
});
const localePath = useLocalePath()

</script>
