<template>
  <div ref="nav" class="sticky top-0 transition-all duration-200 z-50" :class="{ 'mt-8': native }">
    <div class="mx-auto sm:px-6 px-4 max-w-screen-2xl">
      <div class="flex items-center justify-between py-3 pl-5 pr-4 lg:px-6 sm:pr-4 md:pr-6 ">
        <LayoutsNavigationAppLogo />
        <LayoutsNavigationAppIconMobile :open_menu="open_menu" @toggleOpenMenu="toggleOpenMenu" />
        <LayoutsNavigationAppMenu :dataLocale="dataLocale" />
        <LayoutsNavigationAppAction />
      </div>
    </div>
    <LayoutsNavigationAppMobile :open_menu="open_menu" @close="()=>open_menu = false" :dataLocale="dataLocale"/>
  </div>
  <div v-if="native" class="mx-auto sm:px-6 px-4 max-w-screen-2xl">
    <div class="pt-4 pb-4 md:pb-5 lg:pb-4 my-4 rounded-4xl -mt-16 md:-mt-20 bg-neutrals-2">
      <div class="mt-12 md:mt-14 lg:mt-16"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import DataLocale from '~~/config/interface/DataLocale';


const props = defineProps({
  native: {
    type: Boolean,
    default: true
  },
})

const open_menu = ref(false)
const toggleOpenMenu = () => {
  open_menu.value = !open_menu.value
}

const route = useRoute();

watch(route, value => {
  open_menu.value = false
}, { deep: true, immediate: true })

var nav = ref<HTMLElement>();
onMounted(() => {
  if (window.scrollY > 32) {
    SetFixedNavigation(true);
  }
  window.addEventListener("scroll", function (e) {
    var top = window.scrollY;
    if (top >= 32) {
      SetFixedNavigation(true);
    } else {
      SetFixedNavigation(false);
    }
  });
});
function SetFixedNavigation(status: Boolean) {
  var classList = [
    "bg-white",
    "dark:bg-neutrals-15",
    "bg-opacity-95",
    "shadow-subtle",
  ];

  if (status) {
    classList.forEach((cl) => {
      nav.value?.classList.add(cl);
    });
  } else {
    classList.forEach((cl) => {
      nav.value?.classList.remove(cl);
    });
  }
}

const dataLocale = new DataLocale([
  {
    locale: "en",
    marketing: "in Marketing",
    education: "in Education",
    personal: "for Personal Use",
    nonprofit: "for Non-Profit",
    business: "for Business",
  },
  {
    locale: "vi",
    marketing: "Marketing",
    education: "Giáo dục",
    personal: "Sử dụng Cá nhân",
    nonprofit: "Tổ chức Phi lợi nhuận",
    business: "Doanh nghiệp",
  },
  {
    locale: "de",
    marketing: "Marketing",
    education: "Bildung",
    personal: "Persönlicher Gebrauch",
    nonprofit: "Gemeinnützig",
    business: "Unternehmen",
  },
  {
    locale: "fr",
    marketing: "Marketing",
    education: "Éducation",
    personal: "Usage Personnel",
    nonprofit: "Sans But Lucratif",
    business: "Entreprise",
  },
  {
    locale: "it",
    marketing: "Marketing",
    education: "Istruzione",
    personal: "Uso Personale",
    nonprofit: "Senza scopo di lucro",
    business: "Aziende",
  },
  {
    locale: "es",
    marketing: "Marketing",
    education: "Educación",
    personal: "Uso personal",
    nonprofit: "Sin fines de lucro",
    business: "Empresa",
  },
  {
    locale: "nl",
    marketing: "Marketing",
    education: "Onderwijs",
    personal: "Persoonlijk gebruik",
    nonprofit: "Zonder winstoogmerk",
    business: "Zakelijk",
  },
  {
    locale: "pt",
    marketing: "Marketing",
    education: "Educação",
    personal: "Uso pessoal",
    nonprofit: "Sem fins lucrativos",
    business: "Negócios",
  },
  {
    locale: "zh",
    marketing: "市场营销",
    education: "教育",
    personal: "个人使用",
    nonprofit: "非营利",
    business: "商业",
  },
  {
    locale: "ja",
    marketing: "マーケティング",
    education: "教育",
    personal: "個人使用",
    nonprofit: "非営利団体向け",
    business: "ビジネス",
  },
]);
</script>
