<template>
    <ul class="flex items-center gap-2">
        <li class="cursor-pointer"
            :class="{ 'grayscale-0': currentLocale.code !== locale.code, 'grayscale hover:grayscale-0': currentLocale.code == locale.code }"
            @click="setLocale(locale)" v-for="locale in locales">
            <img :src="locale.icon" class="w-5" :alt="locale.name" />
        </li>
    </ul>
</template>
<script lang="ts" setup>
const { locale, locales } = useI18n();

var currentLocale = computed(() => {
    return locales.value.find((i) => i.code == locale.value);
});
const setLocale = (l: { domain: string }) => {
  const url = window.location.protocol + "//" + l.domain + useRoute().fullPath
  window.location.href = url;
};

</script>