<template>
  <div class="relative">
    <button
      ref="button"
      class="text-neutrals-15 whitespace-nowrap hover:bg-neutrals-10 md:inline-flex hidden items-center text-md font-normal focus:outline-none group hover:bg-opacity-10 py-2.5 px-4 xl:px-5 rounded-full transition dark:text-white"
      type="button"
      @click="onToggleDropdown"
    >
      <span>
        <slot name="label"></slot>
      </span>
      <ChevronDownIcon
        stroke-width="1"
       
        class="text-neutrals-15 opacity-75 ml-2 h-4 w-4 dark:text-white"
      />
    </button>
    <transition
      enter-active-class="transition  ease-in"
      enter-from-class=" translate-y-1 opacity-0"
      enter-to-class="  translate-y-0 opacity-100"
      leave-active-class="transition  ease-out"
      leave-from-class=" translate-y-0  opacity-100"
      leave-to-class=" translate-y-1  opacity-0"
      appear
    >
      <div
        v-show="statusNav"
        class="absolute z-10 px-2 mt-2 w-max-content left-1/2 sm:px-0 transform -translate-x-1/2 "
      >
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ChevronDownIcon } from "vue-tabler-icons";
const button = ref(null);
var statusNav = ref(false);
onClickOutside(button, (event) => {
  statusNav.value = false;
});
function onToggleDropdown() {
  statusNav.value = !statusNav.value;
}
</script>
