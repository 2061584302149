<template>
  <div>
    <LayoutsNavigationApp :native="true" />
    <div class="relative">
      <div class="max-w-screen-xl mx-auto mt-10 md:mt-12 lg:mt-16 xl:mt-20">
        <ErrorIcon class="w-72 mx-auto mb-8" />
        <div class="flex items-center flex-col justify-center gap-4">
          <h1 class="text-3xl mx-auto text-center ">{{ error?.message }}</h1>
          <div class="flex items-center gap-4 mt-10">
            <nuxt-link
              to="/"
              class="flex items-center gap-2 h-[50px] px-8 rounded-3xl bg-success text-white drop-shadow-md hover:drop-shadow-xl"
              >Home</nuxt-link
            >
            <a
              href="https://app.qrgateway.com/sign-up"
              target="_blank"
              class="flex items-center gap-2 h-[50px] px-8 rounded-3xl bg-white text-black drop-shadow-md hover:drop-shadow-xl"
              >{{ $t('try_our_service') }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <LayoutsFooterApp />
  </div>
</template>

<script setup lang="ts">
import ErrorIcon from "~/assets/svg/error-pana.svg?component";
const props = defineProps({
  error: Object,
});

useHead({
  title: "QR Gateway",
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
  ],
});
definePageMeta({
  layout: "default",
});
const handleError = () => clearError({ redirect: "/" });
</script>
