<template>
    <div class="w-fulll footer-top mt-8">
        <div class="max-w-screen-xl mx-auto py-8 lg:px-0 px-4">
            <div class="flex sm:items-center justify-around text-white sm:flex-row flex-col sm:gap-0 gap-4">
                <div class="flex flex-col">
                    <h4 class="font-semibold text-xl md:text-2xl">{{ dataLocale.data.title }}</h4>
                    <p class="md:text-lg xl:text-xl">{{ dataLocale.data.description }}
                    </p>
                    
                </div>
                <a href="https://app.qrgateway.com/sign-up" target="_blank"
                    class="flex items-center gap-2 h-10 xs:h-[50px] w-fit px-8 flex-shrink-0 rounded-3xl bg-white text-black drop-shadow-md hover:drop-shadow-xl">{{
                        $t('try_free_now') }}
                    <ArrowRightIcon />
                </a>
            </div>
        </div>
    </div>
    <footer class="grid px-4 py-8 lg:py-16 sm:px-6 bg-white">
        <div class="mx-auto sm:px-7 px-4 dark:text-white max-w-screen-xl">
            <div class="max-w-screen-xl mx-auto">
                <div class="grid gap-8 mt-4 lg:grid-cols-12">
                    <div class="grid items-center gap-x-8 lg:block lg:col-span-4">
                        <p>
                            <IconQrGateway class="w-12" />
                        </p>
                        <div class="mt-4 text-base xs:text-lg">
                            <div class="italic">"{{ $t('footer.quote') }}"</div>
                            <div class="mt-2">
                                <p>{{ $t('footer.description') }}</p>
                                <p class="mt-4">{{ $t('footer.trademark') }}</p>
                            </div>
                            <ClientOnly>
                                <div class="mt-4">
                                    <a href="https://www.producthunt.com/products/qr-gateway/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-qr&#0045;gateway" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=534876&theme=light" alt="QR&#0032;Gateway - Create&#0044;&#0032;manage&#0044;track&#0032;and&#0032;design&#0032;QR&#0032;codes&#0032;your&#0032;way | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
                                </div>
                            </ClientOnly>
                        </div>
                    </div>
                    <div class="lg:col-span-8 lg:col-start-6">
                        <div
                            class="grid grid-cols-1 xs:grid-cols-2 gap-10 md:-mr-12 lg:grid-col-3 md:grid-cols-3 text-base">
                            <div>
                                <h4 class="font-bold text-xl text-black">{{ $t('footer.info') }}</h4>
                                <ul class="mt-3 space-y-3 md:mt-5 lg:space-y-4">
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/features">{{ $t('features') }}</nuxt-link>
                                    </li>

                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/pricing">Pricing</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/blog">Blog</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/contact-us">{{ $t('footer.contact') }}</nuxt-link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h4 class="font-bold text-xl text-black">{{ $t('footer.legal') }}</h4>
                                <ul class="mt-3 space-y-3 md:mt-5 lg:space-y-4">
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/page/cookies-policy">{{ $t('footer.cookie_policy') }}</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/page/privacy-policy">{{ $t('footer.privacy_policy') }}</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/page/terms-of-conditions">{{ $t('footer.terms') }}</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/page/acceptable-use-policy">{{ $t('footer.accept') }}</nuxt-link>
                                    </li>
                                    <li class="transition text-grow hover:text-black">
                                        <nuxt-link to="/page/data-processing-agreenent">{{ $t('footer.data_process') }}</nuxt-link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h4 class="font-bold text-xl text-black">{{ $t('footer.tool') }}</h4>
                                <ul class="mt-3 space-y-3 md:mt-5 lg:space-y-4">
                                    <li class="transition text-grow hover:text-black" v-for="tool in toolLocale.data">
                                        <nuxt-link :to="tool.url">{{ tool.name }}</nuxt-link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-10 border-t py-6">
                <div class="grid gap-8 justify-items-center lg:grid-cols-3">
                    <div class="lg:justify-self-start lg:col-span-2">
                        <div class="flex flex-wrap justify-center -mx-4 lg:mx-0 lg:space-x-4 lg:justify-start">
                            <LayoutsNavigationLanguageSwitchLocaleFooter />
                        </div>
                    </div>
                    <div class="lg:justify-self-end">
                        <ul class="flex items-center space-x-6">
                            <li v-for="social in socials">
                                <a :href="social.link" target="_blank" rel="noopener noreferrer" :aria-label="social.name">
                                    <component v-bind:is="social.icon" stroke-width="1" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-center block py-2">
                © {{ year }} QRGateway. {{ $t('footer.all_right') }}
            </div>
        </div>

    </footer>
</template>
<script lang="ts">
import { ArrowRightIcon, BrandFacebookIcon, BrandTwitterIcon, BrandYoutubeIcon, BrandBehanceIcon, BrandLinkedinIcon } from "vue-tabler-icons"

import IconQrGateway from "~/assets/svg/icon.svg?component";
import DataLocale from "~~/config/interface/DataLocale";
import MultiDataLocale from "~~/config/interface/MultiDataLocale";


export default defineComponent({
    components: {
        ArrowRightIcon, BrandFacebookIcon, BrandTwitterIcon, BrandYoutubeIcon, BrandBehanceIcon, BrandLinkedinIcon, IconQrGateway
    },
    setup() {
        const dataLocale = new DataLocale([
            {
                locale: "en",
                title: "Become a QR Code pro",
                description: "Variety of QR Code solutions with full customization, tracking and more"
            },
            {
                locale: "vi",
                title: "Trở thành chuyên gia QR Code",
                description: "Các giải pháp QR Code đa dạng với tính năng tùy chỉnh đầy đủ, theo dõi và nhiều hơn thế nữa"
            },
            {
                locale: "es",
                title: "Conviértete en un experto en códigos QR",
                description: "Variedad de soluciones de códigos QR con personalización completa, seguimiento y más"
            },
            {
                locale: "fr",
                title: "Devenez un pro du code QR",
                description: "Une variété de solutions de code QR avec une personnalisation complète, un suivi et plus encore"
            },
            {
                locale: "de",
                title: "Werden Sie ein QR-Code-Profi",
                description: "Verschiedene QR-Code-Lösungen mit vollständiger Anpassung, Nachverfolgung und mehr"
            },
            {
                locale: "it",
                title: "Diventa un professionista dei codici QR",
                description: "Varie soluzioni QR Code con personalizzazione completa, tracciamento e altro ancora"
            },
            {
                locale: "nl",
                title: "Word een QR Code pro",
                description: "Verschillende QR Code-oplossingen met volledige aanpassing, tracking en meer"
            }
            , {
                locale: "ja",
                title: "QRコードのプロになろう",
                description: "完全カスタマイズ、トラッキングなどを備えたさまざまなQRコードソリューション"
            },
            {
                locale: "zh",
                title: "成为 QR Code 专家",
                description: "多种 QR Code 解决方案，具有完全自定义、跟踪等功能"
            },
            {
                locale: "pt",
                title: "Torne-se um profissional de QR Code",
                description: "Variedade de soluções de QR Code com personalização completa, rastreamento e mais"
            }
        ])
        const toolLocale = new MultiDataLocale([
            {
                locale: "en",
                data: [
                    {
                        name: "Text to QR Code",
                        url: "/"
                    },
                    {
                        name: "Url to QR Code",
                        url: "/url"
                    },
                    {
                        name: "Multimedia to QR Code",
                        url: "/multimedia"
                    },
                    {
                        name: "Document to QR Code",
                        url: "/document"
                    },
                    {
                        name: "Scan QR Code",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "vi",
                data: [
                    {
                        name: "Chuyển đổi văn bản thành mã QR",
                        url: "/"
                    },
                    {
                        name: "Chuyển đổi đường dẫn URL thành mã QR",
                        url: "/url"
                    },
                    {
                        name: "Chuyển đổi multimedia thành mã QR",
                        url: "/multimedia"
                    },
                    {
                        name: "Chuyển đổi tài liệu thành mã QR",
                        url: "/document"
                    },
                    {
                        name: "Quét mã QR",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "de",
                data: [
                    {
                        name: "Text zu QR-Code",
                        url: "/"
                    },
                    {
                        name: "URL zu QR-Code",
                        url: "/url"
                    },
                    {
                        name: "Multimedia zu QR-Code",
                        url: "/multimedia"
                    },
                    {
                        name: "Dokument zu QR-Code",
                        url: "/document"
                    },
                    {
                        name: "QR-Code scannen",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "fr",
                data: [
                    {
                        name: "Texte en code QR",
                        url: "/"
                    },
                    {
                        name: "URL en code QR",
                        url: "/url"
                    },
                    {
                        name: "Multimédia en code QR",
                        url: "/multimedia"
                    },
                    {
                        name: "Document en code QR",
                        url: "/document"
                    },
                    {
                        name: "Scanner le code QR",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "es",
                data: [
                    {
                        name: "Texto a código QR",
                        url: "/"
                    },
                    {
                        name: "URL a código QR",
                        url: "/url"
                    },
                    {
                        name: "Multimedia a código QR",
                        url: "/multimedia"
                    },
                    {
                        name: "Documento a código QR",
                        url: "/document"
                    },
                    {
                        name: "Escanear código QR",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "it",
                data: [
                    {
                        name: "Testo in codice QR",
                        url: "/"
                    },
                    {
                        name: "URL in codice QR",
                        url: "/url"
                    },
                    {
                        name: "Multimedia in codice QR",
                        url: "/multimedia"
                    },
                    {
                        name: "Documento in codice QR",
                        url: "/document"
                    },
                    {
                        name: "Scansione codice QR",
                        url: "/qr-code-scanner"
                    },
                ]
            },

            {
                locale: "nl",
                data: [
                    {
                        name: "Tekst naar QR-code",
                        url: "/"
                    },
                    {
                        name: "URL naar QR-code",
                        url: "/url"
                    },
                    {
                        name: "Multimedia naar QR-code",
                        url: "/multimedia"
                    },
                    {
                        name: "Document naar QR-code",
                        url: "/document"
                    },
                    {
                        name: "QR-code scannen",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "pt",
                data: [
                    {
                        name: "Texto para código QR",
                        url: "/"
                    },
                    {
                        name: "URL para código QR",
                        url: "/url"
                    },
                    {
                        name: "Multimídia para código QR",
                        url: "/multimedia"
                    },
                    {
                        name: "Documento para código QR",
                        url: "/document"
                    },
                    {
                        name: "Digitalizar código QR",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "zh",
                data: [
                    {
                        name: "文本转QR码",
                        url: "/"
                    },
                    {
                        name: "URL转QR码",
                        url: "/url"
                    },
                    {
                        name: "多媒体转QR码",
                        url: "/multimedia"
                    },
                    {
                        name: "文档转QR码",
                        url: "/document"
                    },
                    {
                        name: "扫描QR码",
                        url: "/qr-code-scanner"
                    },
                ]
            },
            {
                locale: "ja",
                data: [
                    {
                        name: "テキストをQRコードに変換",
                        url: "/"
                    },
                    {
                        name: "URLをQRコードに変換",
                        url: "/url"
                    },
                    {
                        name: "マルチメディアをQRコードに変換",
                        url: "/multimedia"
                    },
                    {
                        name: "ドキュメントをQRコードに変換",
                        url: "/document"
                    },
                    {
                        name: "QRコードをスキャン",
                        url: "/qr-code-scanner"
                    },
                ]
            }
        ])
        return {
            dataLocale,
            toolLocale,
            year: ref((new Date).getFullYear()),
            socials: ref([
                {
                    icon: "BrandFacebookIcon",
                    link: "https://www.facebook.com/qrgateway",
                    name: "QRGateway on facebook"
                },
                {
                    icon: "BrandTwitterIcon",
                    link: "https://twitter.com/qrgateway",
                    name: "QRGateway on twitter"
                },
                {
                    icon: "BrandYoutubeIcon",
                    link: "https://www.youtube.com/c/qrgateway",
                    name: "QRGateway on youtube"
                },
                {
                    icon: "BrandBehanceIcon",
                    link: "https://www.behance.net/qrgateway",
                    name: "QRGateway on behance"
                },
                {
                    icon: "BrandLinkedinIcon",
                    link: "https://www.linkedin.com/company/qrgateway",
                    name: "QRGateway on linkedin"
                },
            ])
        }
    }

})
</script>
<style lang="scss">
.footer-top {
    width: 100%;
    background-color: var(--success);
    background-position: bottom;
    background-repeat: repeat;
    background-image: url(/images/bg_pattern.png) !important;
}
</style>