import { default as _91type_935FMHdp047nMeta } from "/var/www/qrgateway.com/pages/[type].vue?macro=true";
import { default as batch_45qr_45code7T8UsPoD5EMeta } from "/var/www/qrgateway.com/pages/batch-qr-code.vue?macro=true";
import { default as _91slug_93NYnx95Ix6eMeta } from "/var/www/qrgateway.com/pages/blog/[slug].vue?macro=true";
import { default as indexW2gGXXnLGEMeta } from "/var/www/qrgateway.com/pages/blog/index.vue?macro=true";
import { default as contact_45usbPrk7iTuDqMeta } from "/var/www/qrgateway.com/pages/contact-us.vue?macro=true";
import { default as featuresDgq7rEJmLyMeta } from "/var/www/qrgateway.com/pages/features.vue?macro=true";
import { default as indexhgYNiNTem8Meta } from "/var/www/qrgateway.com/pages/index.vue?macro=true";
import { default as acceptable_45use_45policy4zouXgamzIMeta } from "/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue?macro=true";
import { default as cookies_45policyz2biLGb5NNMeta } from "/var/www/qrgateway.com/pages/page/cookies-policy.vue?macro=true";
import { default as data_45processing_45agreenentEZhkun92Q9Meta } from "/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue?macro=true";
import { default as legal_45noticedx8nYcIqXaMeta } from "/var/www/qrgateway.com/pages/page/legal-notice.vue?macro=true";
import { default as privacy_45policy8U6qqiWyt8Meta } from "/var/www/qrgateway.com/pages/page/privacy-policy.vue?macro=true";
import { default as terms_45of_45conditionscPV1psKqmwMeta } from "/var/www/qrgateway.com/pages/page/terms-of-conditions.vue?macro=true";
import { default as pricingJd5YNreAAxMeta } from "/var/www/qrgateway.com/pages/pricing.vue?macro=true";
import { default as _91slug_93svn7BbtTIrMeta } from "/var/www/qrgateway.com/pages/q/[slug].vue?macro=true";
import { default as qr_45code_45scannerAJg275Zs6IMeta } from "/var/www/qrgateway.com/pages/qr-code-scanner.vue?macro=true";
import { default as qr_45codes_45for_45nonprofithPdROk4WS9Meta } from "/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue?macro=true";
import { default as qr_45codes_45for_45personal94LoK7HadmMeta } from "/var/www/qrgateway.com/pages/qr-codes-for-personal.vue?macro=true";
import { default as qr_45codes_45foryfy74yhfnLMeta } from "/var/www/qrgateway.com/pages/qr-codes-for.vue?macro=true";
import { default as qr_45codes_45in_45education4QDd56L08yMeta } from "/var/www/qrgateway.com/pages/qr-codes-in-education.vue?macro=true";
import { default as qr_45codes_45in_45marketingZi9SuMZHSmMeta } from "/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue?macro=true";
import { default as component_45stubz3gbA4SX9CMeta } from "/var/www/qrgateway.com/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubz3gbA4SX9C } from "/var/www/qrgateway.com/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "type___en",
    path: "/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___vi",
    path: "/vi/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___de",
    path: "/de/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___es",
    path: "/es/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___fr",
    path: "/fr/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___it",
    path: "/it/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___ja",
    path: "/ja/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___nl",
    path: "/nl/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___pt",
    path: "/pt/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "type___zh",
    path: "/zh/:type()",
    meta: _91type_935FMHdp047nMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/[type].vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___en",
    path: "/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___vi",
    path: "/vi/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___de",
    path: "/de/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___es",
    path: "/es/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___fr",
    path: "/fr/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___it",
    path: "/it/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___ja",
    path: "/ja/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___nl",
    path: "/nl/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___pt",
    path: "/pt/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "batch-qr-code___zh",
    path: "/zh/batch-qr-code",
    component: () => import("/var/www/qrgateway.com/pages/batch-qr-code.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___vi",
    path: "/vi/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___ja",
    path: "/ja/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pt",
    path: "/pt/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___zh",
    path: "/zh/blog/:slug()",
    component: () => import("/var/www/qrgateway.com/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___vi",
    path: "/vi/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ja",
    path: "/ja/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___zh",
    path: "/zh/blog",
    component: () => import("/var/www/qrgateway.com/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___vi",
    path: "/vi/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___de",
    path: "/de/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___es",
    path: "/es/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___fr",
    path: "/fr/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___it",
    path: "/it/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___ja",
    path: "/ja/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___nl",
    path: "/nl/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___pt",
    path: "/pt/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___zh",
    path: "/zh/contact-us",
    component: () => import("/var/www/qrgateway.com/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "features___en",
    path: "/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___vi",
    path: "/vi/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___de",
    path: "/de/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___es",
    path: "/es/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___fr",
    path: "/fr/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___it",
    path: "/it/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___ja",
    path: "/ja/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___nl",
    path: "/nl/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___pt",
    path: "/pt/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "features___zh",
    path: "/zh/features",
    component: () => import("/var/www/qrgateway.com/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___vi",
    path: "/vi",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/ja",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/var/www/qrgateway.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___en",
    path: "/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___vi",
    path: "/vi/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___de",
    path: "/de/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___es",
    path: "/es/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___fr",
    path: "/fr/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___it",
    path: "/it/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___ja",
    path: "/ja/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___nl",
    path: "/nl/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___pt",
    path: "/pt/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-acceptable-use-policy___zh",
    path: "/zh/page/acceptable-use-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/acceptable-use-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___en",
    path: "/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___vi",
    path: "/vi/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___de",
    path: "/de/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___es",
    path: "/es/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___fr",
    path: "/fr/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___it",
    path: "/it/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___ja",
    path: "/ja/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___nl",
    path: "/nl/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___pt",
    path: "/pt/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-cookies-policy___zh",
    path: "/zh/page/cookies-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/cookies-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___en",
    path: "/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___vi",
    path: "/vi/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___de",
    path: "/de/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___es",
    path: "/es/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___fr",
    path: "/fr/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___it",
    path: "/it/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___ja",
    path: "/ja/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___nl",
    path: "/nl/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___pt",
    path: "/pt/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-data-processing-agreenent___zh",
    path: "/zh/page/data-processing-agreenent",
    component: () => import("/var/www/qrgateway.com/pages/page/data-processing-agreenent.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___en",
    path: "/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___vi",
    path: "/vi/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___de",
    path: "/de/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___es",
    path: "/es/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___fr",
    path: "/fr/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___it",
    path: "/it/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___ja",
    path: "/ja/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___nl",
    path: "/nl/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___pt",
    path: "/pt/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-legal-notice___zh",
    path: "/zh/page/legal-notice",
    component: () => import("/var/www/qrgateway.com/pages/page/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___en",
    path: "/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___vi",
    path: "/vi/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___de",
    path: "/de/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___es",
    path: "/es/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___fr",
    path: "/fr/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___it",
    path: "/it/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___ja",
    path: "/ja/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___nl",
    path: "/nl/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___pt",
    path: "/pt/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-privacy-policy___zh",
    path: "/zh/page/privacy-policy",
    component: () => import("/var/www/qrgateway.com/pages/page/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___en",
    path: "/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___vi",
    path: "/vi/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___de",
    path: "/de/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___es",
    path: "/es/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___fr",
    path: "/fr/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___it",
    path: "/it/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___ja",
    path: "/ja/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___nl",
    path: "/nl/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___pt",
    path: "/pt/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "page-terms-of-conditions___zh",
    path: "/zh/page/terms-of-conditions",
    component: () => import("/var/www/qrgateway.com/pages/page/terms-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en",
    path: "/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___vi",
    path: "/vi/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___de",
    path: "/de/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___es",
    path: "/es/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___fr",
    path: "/fr/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___it",
    path: "/it/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___ja",
    path: "/ja/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___nl",
    path: "/nl/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___pt",
    path: "/pt/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___zh",
    path: "/zh/pricing",
    component: () => import("/var/www/qrgateway.com/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "q-slug___en",
    path: "/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___vi",
    path: "/vi/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___de",
    path: "/de/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___es",
    path: "/es/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___fr",
    path: "/fr/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___it",
    path: "/it/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___ja",
    path: "/ja/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___nl",
    path: "/nl/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___pt",
    path: "/pt/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "q-slug___zh",
    path: "/zh/q/:slug()",
    meta: _91slug_93svn7BbtTIrMeta || {},
    component: () => import("/var/www/qrgateway.com/pages/q/[slug].vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___en",
    path: "/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___vi",
    path: "/vi/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___de",
    path: "/de/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___es",
    path: "/es/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___fr",
    path: "/fr/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___it",
    path: "/it/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___ja",
    path: "/ja/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___nl",
    path: "/nl/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___pt",
    path: "/pt/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-code-scanner___zh",
    path: "/zh/qr-code-scanner",
    component: () => import("/var/www/qrgateway.com/pages/qr-code-scanner.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___en",
    path: "/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___vi",
    path: "/vi/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___de",
    path: "/de/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___es",
    path: "/es/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___fr",
    path: "/fr/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___it",
    path: "/it/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___ja",
    path: "/ja/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___nl",
    path: "/nl/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___pt",
    path: "/pt/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-nonprofit___zh",
    path: "/zh/qr-codes-for-nonprofit",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-nonprofit.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___en",
    path: "/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___vi",
    path: "/vi/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___de",
    path: "/de/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___es",
    path: "/es/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___fr",
    path: "/fr/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___it",
    path: "/it/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___ja",
    path: "/ja/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___nl",
    path: "/nl/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___pt",
    path: "/pt/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for-personal___zh",
    path: "/zh/qr-codes-for-personal",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for-personal.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___en",
    path: "/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___vi",
    path: "/vi/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___de",
    path: "/de/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___es",
    path: "/es/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___fr",
    path: "/fr/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___it",
    path: "/it/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___ja",
    path: "/ja/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___nl",
    path: "/nl/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___pt",
    path: "/pt/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-for___zh",
    path: "/zh/qr-codes-for",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-for.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___en",
    path: "/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___vi",
    path: "/vi/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___de",
    path: "/de/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___es",
    path: "/es/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___fr",
    path: "/fr/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___it",
    path: "/it/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___ja",
    path: "/ja/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___nl",
    path: "/nl/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___pt",
    path: "/pt/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-education___zh",
    path: "/zh/qr-codes-in-education",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-education.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___en",
    path: "/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___vi",
    path: "/vi/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___de",
    path: "/de/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___es",
    path: "/es/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___fr",
    path: "/fr/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___it",
    path: "/it/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___ja",
    path: "/ja/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___nl",
    path: "/nl/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___pt",
    path: "/pt/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: "qr-codes-in-marketing___zh",
    path: "/zh/qr-codes-in-marketing",
    component: () => import("/var/www/qrgateway.com/pages/qr-codes-in-marketing.vue").then(m => m.default || m)
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-code-generator",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-code-generator/",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-code-types",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-code-types/{type}",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-codes-for/personal",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/qr-codes-for/education",
    component: component_45stubz3gbA4SX9C
  },
  {
    name: component_45stubz3gbA4SX9CMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubz3gbA4SX9C
  }
]