export default class MultiDataLocale {
  public data: Array<any> = [];
  public any_content: {
    [key: string]: any;
  } = {};
  constructor(
    data: Array<{
      locale: string;
      data: Array<any>;
      [key: string]: any;
    }>
  ) {
    const { locale } = useI18n();
    var localeData =
      data.find((item) => item.locale == locale.value) ?? data[0];

    this.data = localeData.data;
    this.any_content = localeData;
    delete this.any_content.data
  }
}