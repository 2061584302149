<template>
  <div class="relative">
    <button
      class="text-neutrals-15 whitespace-nowrap hover:bg-neutrals-10 dark:text-white text-md font-normal hover:bg-opacity-10 py-2.5 px-4 rounded-full transition gap-2 flex items-center hover:opacity-90"
      type="button"
      ref="button"
      @click="onToggleDropdown"
    >
      <img :src="currentLocale.icon" class="w-5" :alt="currentLocale.name" />
      <span class="flex gap-1 items-center" >
        <span class="sr-only" >{{ currentLocale.name }}</span>
        <ChevronDownIcon
          stroke-width="1"
          class="text-neutrals-15 opacity-75 h-4 w-4 dark:text-white"
        />
      </span>
    </button>
    <transition
      enter-active-class="transition  ease-in"
      enter-from-class=" translate-y-1 opacity-0"
      enter-to-class="  translate-y-0 opacity-100"
      leave-active-class="transition  ease-out"
      leave-from-class=" translate-y-0  opacity-100"
      leave-to-class=" translate-y-1  opacity-0"
      appear
    >
      <div
        v-show="statusSwitchLocale"
        class="absolute z-10 px-2 mt-2 w-max-content left-1/2 sm:px-0 transform -translate-x-1/2"
      >
        <div
          class="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5"
        >
          <div class="relative p-2 space-y-1 bg-white dark:bg-neutrals-15">
            <button
              @click="setLocale(locale)"
              v-for="locale in availableLocales"
              class="px-3 py-2 transition rounded-lg hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white flex items-center gap-2"
            >
              <img :src="locale.icon" class="w-5" :alt="locale.name" />
              <span class="font-tinybold">{{ locale.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { ChevronDownIcon } from "vue-tabler-icons";

const { locale, locales } = useI18n();

const route = useRoute();

const setLocale = (l: { domain: string }) => {
  const url = window.location.protocol + "//" + l.domain + route.fullPath;
  window.location.href = url;
};

const availableLocales = computed(() => {
  return (locales.value as Array<any>).filter((i) => i.code !== locale.value);
});
var currentLocale = computed(() => {
  return (locales.value as Array<any>).find((i) => i.code == locale.value);
});

var button = ref(null);
var statusSwitchLocale = ref(false);
onClickOutside(button, (event) => {
  statusSwitchLocale.value = false;
});
function onToggleDropdown() {
  statusSwitchLocale.value = !statusSwitchLocale.value;
}
</script>
