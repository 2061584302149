<template>
  <div class="flex -my-2 -mr-2 lg:hidden">
    <button class="relative group" @click="toggleOpenMenu()" aria-label="Navigation">
      <div
        class="relative flex overflow-hidden items-center justify-center w-[40px] h-[40px]  md:w-[60px] md:h-[60px] transform transition-all ring-0 duration-200">
        <div
          class="flex flex-col justify-between w-[30px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
          <div class="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left"
            :class="{ 'translate-x-10': open_menu }">
          </div>
          <div class="bg-black h-[2px] w-7 rounded transform transition-all duration-300  delay-75"
            :class="{ 'translate-x-10': open_menu }">
          </div>
          <div class="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left delay-150"
            :class="{ 'translate-x-10': open_menu }">
          </div>
          <div
            class="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10  flex w-0"
            :class="{ 'translate-x-0 w-12': open_menu }">
            <div class="absolute bg-black h-[2px] w-7 transform transition-all duration-500 rotate-0 delay-300 "
              :class="{ 'rotate-45': open_menu }">
            </div>
            <div class="absolute bg-black h-[2px] w-7 transform transition-all duration-500 -rotate-0 delay-300"
              :class="{ '-rotate-45': open_menu }">
            </div>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  open_menu: {
    type: Boolean,
    default: false
  }
})
const emit: Function = defineEmits();

const toggleOpenMenu = () => {
  emit('toggleOpenMenu');
}
</script>
  