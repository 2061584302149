<template>
  <nav class="ml-6 space-x-2 xl:ml-0 xl:space-x-4 2xl:space-x-6 hidden lg:flex lg:flex-1">
    <RegularItemMenu :to="('/batch-qr-code')"> 🔥 {{ $t('batch') }} </RegularItemMenu>
    <RegularItemMenu :to="('/qr-code-scanner')">{{ $t('scanner') }} </RegularItemMenu>
   
    <PropItemMenu>
      <template v-slot:label>{{ $t('solutions') }}</template>
      <template v-slot:dropdown>
        <div class="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5 p-4 bg-white">
          <CategoriesSolution :dataLocale="dataLocale" />
        </div>
      </template>
    </PropItemMenu>
    <RegularItemMenu :to="('/pricing')" class="text-primary 2xl:block hidden">Pricing </RegularItemMenu>
    <PropItemMenu>
      <template v-slot:label>Resources</template>
      <template v-slot:dropdown>
        <div class="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5">
          <div class="relative p-2 space-y-1 bg-white dark:bg-neutrals-15">
            <nuxt-link
              class="px-3 2xl:hidden block py-2 transition rounded-lg md:block text-neutrals-15 hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white"
              :to="localePath('/pricing')">Pricing</nuxt-link>
            <nuxt-link
              class="px-3 2xl:hidden block py-2 transition rounded-lg md:block text-neutrals-15 hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white"
              :to="localePath('/features')">{{$t('features')}}</nuxt-link>
            <nuxt-link
              class="px-3 py-2 transition rounded-lg md:block text-neutrals-15 hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white"
              :to="localePath('/contact-us')">{{$t('contact')}}</nuxt-link>
            <nuxt-link
              class="px-3 py-2 transition rounded-lg md:block text-neutrals-15 hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white"
              :to="localePath('/page/legal-notice')">{{$t('footer.legal')}}</nuxt-link>
            <nuxt-link
              class="px-3 py-2 transition rounded-lg md:block text-neutrals-15 hover:bg-neutrals-10 hover:bg-opacity-10 dark:text-white"
              :to="localePath('/blog')">Blog </nuxt-link>
          </div>
        </div>
      </template>
    </PropItemMenu>
  </nav>
</template>
<script lang="ts" setup>
import RegularItemMenu from "./menu/RegularItemMenu.vue";
import PropItemMenu from "./menu/PropItemMenu.vue";
import CategoriesSolution from "./solutions/CategoriesSolution.vue"
const localePath = useLocalePath()
const props = defineProps({
  dataLocale: {
    type: Object as PropType<{
      data: any;
    }>,
    required: true,
  },
});
</script>
