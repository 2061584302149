import revive_payload_client_4sVQNw7RlN from "/var/www/qrgateway.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/qrgateway.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/qrgateway.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/qrgateway.com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/qrgateway.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/qrgateway.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/qrgateway.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/qrgateway.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/qrgateway.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/var/www/qrgateway.com/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import nuxt_plugin_03YjkxYbK5 from "/var/www/qrgateway.com/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import templates_pwa_client_58195646_yNUw8veUaW from "/var/www/qrgateway.com/.nuxt/templates.pwa.client.58195646.ts";
import plugin_t2GMTTFnMT from "/var/www/qrgateway.com/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/qrgateway.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/qrgateway.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import colorpicker_client_w2ImNmM5pJ from "/var/www/qrgateway.com/plugins/colorpicker.client.ts";
import notification_client_B9MZmzG6b3 from "/var/www/qrgateway.com/plugins/notification.client.ts";
import tawk_messenger_client_AHvCs8Bk4I from "/var/www/qrgateway.com/plugins/tawk-messenger.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  nuxt_plugin_03YjkxYbK5,
  templates_pwa_client_58195646_yNUw8veUaW,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  colorpicker_client_w2ImNmM5pJ,
  notification_client_B9MZmzG6b3,
  tawk_messenger_client_AHvCs8Bk4I
]