
// @ts-nocheck
import locale__var_www_qrgateway_com_langs_en_js from "../langs/en.js";
import locale__var_www_qrgateway_com_langs_vi_js from "../langs/vi.js";
import locale__var_www_qrgateway_com_langs_de_js from "../langs/de.js";
import locale__var_www_qrgateway_com_langs_es_js from "../langs/es.js";
import locale__var_www_qrgateway_com_langs_fr_js from "../langs/fr.js";
import locale__var_www_qrgateway_com_langs_it_js from "../langs/it.js";
import locale__var_www_qrgateway_com_langs_ja_js from "../langs/ja.js";
import locale__var_www_qrgateway_com_langs_nl_js from "../langs/nl.js";
import locale__var_www_qrgateway_com_langs_pt_js from "../langs/pt.js";
import locale__var_www_qrgateway_com_langs_zh_js from "../langs/zh.js";


export const localeCodes =  [
  "en",
  "vi",
  "de",
  "es",
  "fr",
  "it",
  "ja",
  "nl",
  "pt",
  "zh"
]

export const localeLoaders = {
  "en": [{ key: "../langs/en.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_en_js), cache: true }],
  "vi": [{ key: "../langs/vi.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_vi_js), cache: true }],
  "de": [{ key: "../langs/de.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_de_js), cache: true }],
  "es": [{ key: "../langs/es.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_es_js), cache: true }],
  "fr": [{ key: "../langs/fr.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_fr_js), cache: true }],
  "it": [{ key: "../langs/it.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_it_js), cache: true }],
  "ja": [{ key: "../langs/ja.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_ja_js), cache: true }],
  "nl": [{ key: "../langs/nl.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_nl_js), cache: true }],
  "pt": [{ key: "../langs/pt.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_pt_js), cache: true }],
  "zh": [{ key: "../langs/zh.js", load: () => Promise.resolve(locale__var_www_qrgateway_com_langs_zh_js), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "name": "English",
      "code": "en",
      "iso": "en-US",
      "icon": "https://static.qrgateway.com/flag/en.svg",
      "domain": "qrgateway.com",
      "files": [
        "langs/en.js"
      ]
    },
    {
      "name": "Tiếng việt",
      "code": "vi",
      "iso": "vi-VN",
      "icon": "https://static.qrgateway.com/flag/vi.svg",
      "domain": "vi.qrgateway.com",
      "files": [
        "langs/vi.js"
      ]
    },
    {
      "name": "Deutsch",
      "code": "de",
      "iso": "de-DE",
      "icon": "https://static.qrgateway.com/flag/de.svg",
      "domain": "de.qrgateway.com",
      "files": [
        "langs/de.js"
      ]
    },
    {
      "name": "Español",
      "code": "es",
      "iso": "es-ES",
      "icon": "https://static.qrgateway.com/flag/es.svg",
      "domain": "es.qrgateway.com",
      "files": [
        "langs/es.js"
      ]
    },
    {
      "name": "Français",
      "code": "fr",
      "iso": "fr-FR",
      "icon": "https://static.qrgateway.com/flag/fr.svg",
      "domain": "fr.qrgateway.com",
      "files": [
        "langs/fr.js"
      ]
    },
    {
      "name": "Italiano",
      "code": "it",
      "iso": "it-IT",
      "icon": "https://static.qrgateway.com/flag/it.svg",
      "domain": "it.qrgateway.com",
      "files": [
        "langs/it.js"
      ]
    },
    {
      "name": "日本語",
      "code": "ja",
      "iso": "ja-JP",
      "icon": "https://static.qrgateway.com/flag/ja.svg",
      "domain": "ja.qrgateway.com",
      "files": [
        "langs/ja.js"
      ]
    },
    {
      "name": "Nederlands",
      "code": "nl",
      "iso": "nl-NL",
      "icon": "https://static.qrgateway.com/flag/nl.svg",
      "domain": "nl.qrgateway.com",
      "files": [
        "langs/nl.js"
      ]
    },
    {
      "name": "Português",
      "code": "pt",
      "iso": "pt-PT",
      "icon": "https://static.qrgateway.com/flag/pt.svg",
      "domain": "pt.qrgateway.com",
      "files": [
        "langs/pt.js"
      ]
    },
    {
      "name": "简体字",
      "code": "zh",
      "iso": "zh-CN",
      "icon": "https://static.qrgateway.com/flag/zh.svg",
      "domain": "zh.qrgateway.com",
      "files": [
        "langs/zh.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./langs",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "code": "en",
    "iso": "en-US",
    "icon": "https://static.qrgateway.com/flag/en.svg",
    "domain": "qrgateway.com",
    "files": [
      {
        "path": "langs/en.js"
      }
    ]
  },
  {
    "name": "Tiếng việt",
    "code": "vi",
    "iso": "vi-VN",
    "icon": "https://static.qrgateway.com/flag/vi.svg",
    "domain": "vi.qrgateway.com",
    "files": [
      {
        "path": "langs/vi.js"
      }
    ]
  },
  {
    "name": "Deutsch",
    "code": "de",
    "iso": "de-DE",
    "icon": "https://static.qrgateway.com/flag/de.svg",
    "domain": "de.qrgateway.com",
    "files": [
      {
        "path": "langs/de.js"
      }
    ]
  },
  {
    "name": "Español",
    "code": "es",
    "iso": "es-ES",
    "icon": "https://static.qrgateway.com/flag/es.svg",
    "domain": "es.qrgateway.com",
    "files": [
      {
        "path": "langs/es.js"
      }
    ]
  },
  {
    "name": "Français",
    "code": "fr",
    "iso": "fr-FR",
    "icon": "https://static.qrgateway.com/flag/fr.svg",
    "domain": "fr.qrgateway.com",
    "files": [
      {
        "path": "langs/fr.js"
      }
    ]
  },
  {
    "name": "Italiano",
    "code": "it",
    "iso": "it-IT",
    "icon": "https://static.qrgateway.com/flag/it.svg",
    "domain": "it.qrgateway.com",
    "files": [
      {
        "path": "langs/it.js"
      }
    ]
  },
  {
    "name": "日本語",
    "code": "ja",
    "iso": "ja-JP",
    "icon": "https://static.qrgateway.com/flag/ja.svg",
    "domain": "ja.qrgateway.com",
    "files": [
      {
        "path": "langs/ja.js"
      }
    ]
  },
  {
    "name": "Nederlands",
    "code": "nl",
    "iso": "nl-NL",
    "icon": "https://static.qrgateway.com/flag/nl.svg",
    "domain": "nl.qrgateway.com",
    "files": [
      {
        "path": "langs/nl.js"
      }
    ]
  },
  {
    "name": "Português",
    "code": "pt",
    "iso": "pt-PT",
    "icon": "https://static.qrgateway.com/flag/pt.svg",
    "domain": "pt.qrgateway.com",
    "files": [
      {
        "path": "langs/pt.js"
      }
    ]
  },
  {
    "name": "简体字",
    "code": "zh",
    "iso": "zh-CN",
    "icon": "https://static.qrgateway.com/flag/zh.svg",
    "domain": "zh.qrgateway.com",
    "files": [
      {
        "path": "langs/zh.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
