<template>
 
  <LargeItemMenu :to="localePath('/qr-codes-in-marketing')">
    <template v-slot:icon>
      <MarketingIcon  />
    </template>
    <div class="text-base font-esbuild ">
      <span class="text-main font-semibold">{{dataLocale.data.marketing}}</span>
    </div>
  </LargeItemMenu>
  <LargeItemMenu :to="localePath('/qr-codes-in-education')">
    <template v-slot:icon>
      <EducationIcon  />
    </template>
    <div class="text-base font-esbuild">
      <span class="text-main font-semibold">{{dataLocale.data.education}}</span>
    </div>
  </LargeItemMenu>
  <LargeItemMenu :to="localePath('/qr-codes-for-personal')">
    <template v-slot:icon>
      <WidgetIcon  />
    </template>
    <div class="text-base font-esbuild ">
      <span class="text-main font-semibold">{{dataLocale.data.personal}}</span>
    </div>
  </LargeItemMenu>
  <LargeItemMenu :to="localePath('/qr-codes-for-nonprofit')">
    <template v-slot:icon>
      <NonprofitIcon  />
    </template>
    <div class="text-base font-esbuild">
      <span class="text-main font-semibold">{{dataLocale.data.nonprofit}}</span>
    </div>
  </LargeItemMenu>
  <LargeItemMenu :to="localePath('/qr-codes-for')">
    <template v-slot:icon>
      <BusinessIcon  />
    </template>
    <div class="text-base font-esbuild">
      <span class="text-main font-semibold">{{dataLocale.data.business}}</span>
    </div>
  </LargeItemMenu>
</template>
<script lang="ts" setup>
import LargeItemMenu from "../menu/LargeItemMenu.vue";

import MarketingIcon from "~/assets/svg/marketing.svg?component";
import BusinessIcon from "~/assets/svg/business.svg?component";
import NonprofitIcon from "~/assets/svg/nonprofit.svg?component";
import EducationIcon from "~/assets/svg/education.svg?component";
import WidgetIcon from "~/assets/svg/bird.svg?component";
const localePath = useLocalePath()
const props = defineProps({
  dataLocale: {
    type: Object as PropType<{
      data: any;
    }>,
    required: true,
  },
});
</script>
