export default defineNuxtRouteMiddleware((to) => {
  const vcodeValue = to.query.vcode;

  const vcodeCookie = useCookie('vcode', {
    maxAge: 2592000, // 30 days
    httpOnly: false,
    domain: '.qrgateway.com',
    sameSite: 'Lax',
  });

  if (vcodeValue && !vcodeCookie.value) {
    vcodeCookie.value = vcodeValue;
  }
});
