<template>
    <label for="language" class="sr-only">Language:</label> 
    <select id="language"  @change="changeLocale"
        class="bg-gray-50 border border-gray-300 mb-2 text-gray-900 text-sm rounded-lg focus:ring-success focus:border-success block w-full p-2.5">
        <option v-for="lo in availableLocales" :value="lo.code" :selected="locale == lo.code">
            {{ lo.name }}
        </option>
    </select>
</template>
<script lang="ts" setup>
const { locale, locales } = useI18n();
const availableLocales = computed(() => {
    return (locales.value as Array<any>)
});

const changeLocale = (e: Event) => {
    const val = (e.target as HTMLInputElement).value
    var l = locales.value.find(item=>item.code == val);
    setLocale(l)
}
const setLocale = (l: { domain: string }) => {
  const url = window.location.protocol + "//" + l.domain + useRoute().fullPath
  window.location.href = url;
};
</script>