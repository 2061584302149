export default class DataLocale {
  public data: any;
  constructor(
    data: Array<
      {
        locale: string;
      } & {
        [key: string]: any;
      }
    >
  ) {
    const { locale } = useI18n();
    this.data = data.find((item) => item.locale == locale.value) ?? data[0];
  }
}